<template>
  <div>
    <!-- Loader -->
    <full-page-loader
      v-if="loading"
      text="Ladataan laskusivua..."
      class="full-page-loader"
    ></full-page-loader>

    <v-form v-if="!loading" ref="form">
      <v-card class="mt-2">
        <v-card-title>Laskun saajan tiedot</v-card-title>
        <v-container>
          <h4 class="mb-1">Liittyy vuokrasopimukseen</h4>

          <v-row dense>
            <v-col cols="12" sm="6" md="4" lg="3" xl="2">
              <v-autocomplete
                v-model="invoice.contract"
                :items="activeRentalContracts"
                :item-text="nameContractnumber"
                item-value="_id"
                label="Liittyy vuokrasopimukseen"
                no-data-text="Vuokrasopimuksen numero tai vuokralainen"
                outlined
                small-chips
                return-object
                deletable-chips
                :search-input.sync="searchInput"
                @change="
                  searchInput = '';
                  setInvoiceFields($event);
                "
                @input.native="getRentalContract"
                :rules="validations.required"
                hide-details
              >
              </v-autocomplete>
            </v-col>
          </v-row>

          <v-row v-if="invoice.contract" class="mt-1" dense>
            <!-- COL 1 -->
            <v-col cols="12" sm="6" md="6" lg="5" xl="4">
              <h4 class="mb-1">Toimitus</h4>

              <v-row dense>
                <v-col cols="12" md="8" lg="6" xl="4">
                  <v-select
                    v-model="invoice.sendType"
                    outlined
                    dense
                    :items="sendTypes"
                    item-value="val"
                    item-text="text"
                    :rules="validations.required"
                    label="Laskun lähetystapa"
                    hide-details
                  ></v-select>
                </v-col>
                <v-col cols="12" md="8" lg="6" xl="4">
                  <v-radio-group
                    v-model="invoice.sendToApartmentAddress"
                    :mandatory="true"
                    @change="setSendingAddress"
                    style="margin-top: 0px"
                    hide-details
                    label="Laskun lähetys"
                  >
                    <v-radio
                      label="Lasku lähetetään vuokrakohteen osoitetiedoilla"
                      :value="true"
                    ></v-radio>
                    <v-radio
                      label="Lasku lähetetään muilla osoitetiedoilla"
                      :value="false"
                    ></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
            </v-col>

            <!-- COL 2 -->
            <v-col cols="12" sm="6" md="6" lg="7" xl="8">
              <h4 class="mb-1">Laskun saaja</h4>

              <v-row dense>
                <v-col cols="12" sm="6" md="6" lg="6" xl="3">
                  <v-select
                    v-model="principalTenant"
                    outlined
                    dense
                    :items="invoiceReceivers"
                    :item-text="invoiceHelpers.getPrincipalTenantNameInfo"
                    item-value="tenantId._id"
                    label="Laskun saaja"
                    return-object
                    hide-details
                    :rules="validations.required"
                    @change="
                      updateReceiverInfo($event);
                      updateInvoiceLanguage();
                      showPopup('Huomaa muuttunut laskun viitenumero', 'info');
                    "
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="6" md="6" lg="6" xl="3">
                  <v-text-field
                    v-model="invoice.tenant.name"
                    outlined
                    dense
                    :rules="
                      validations.required.concat(maxCharacters({ talenom: 45, ropoCapital: 50 }))
                    "
                    label="Laskun saajan nimi"
                  ></v-text-field>
                </v-col>
                <v-col v-if="invoice.sendType == 'email'" cols="12" sm="12" md="6" lg="6" xl="3">
                  <v-text-field
                    v-model="invoice.tenant.email"
                    label="Laskun saajan email"
                    :rules="validations.required.concat(validations.email)"
                    outlined
                    dense
                    hide-details
                  >
                  </v-text-field>
                </v-col>
                <v-col
                  v-if="
                    invoice.sendType == 'email' &&
                    showBasedOnService({ ropoCapital: true, talenom: false })
                  "
                  cols="12"
                  sm="12"
                  md="6"
                  lg="6"
                  xl="3"
                >
                  <v-text-field
                    v-model="invoice.tenant.email2"
                    label="Laskun saajan email2"
                    :rules="validations.email2"
                    outlined
                    dense
                    hide-details
                  >
                  </v-text-field>
                </v-col>
              </v-row>

              <!-- Evoice -->
              <v-row
                v-if="invoice.sendType == `evoice` || invoice.autoSendType == 'evoice'"
                dense
                class="mt-1 mb-1"
              >
                <v-col cols="12" sm="12" md="6" lg="6" xl="4">
                  <v-btn small color="primary" @click="getTenantEvoiceInformation"
                    >Hae verkkolaskutiedot</v-btn
                  >
                </v-col>
              </v-row>

              <v-row v-if="invoice.sendType == `evoice` || invoice.autoSendType == 'evoice'" dense>
                <v-col cols="12" sm="12" md="6" lg="6" xl="3">
                  <v-text-field
                    v-model="invoice.evoice.evoiceOperator"
                    label="Välittäjä"
                    placeholder="Esim. Basware Oyj"
                    :rules="validations.required"
                    outlined
                    dense
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="6" lg="6" xl="3">
                  <v-text-field
                    v-model="invoice.evoice.evoiceAddress"
                    label="Verkkolaskuosoite"
                    :rules="validations.required"
                    outlined
                    dense
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="6" lg="6" xl="3">
                  <v-text-field
                    v-model="invoice.evoice.evoiceId"
                    label="Operaattoritunnus"
                    placeholder="esim. BAWCFI22"
                    :rules="validations.required"
                    outlined
                    dense
                    hide-details
                  ></v-text-field>
                </v-col>
              </v-row>

              <!-- Billing address -->
              <h4 class="mb-1 mt-1">Laskutusosoite</h4>

              <v-row dense>
                <v-col
                  v-if="!invoice.sendToApartmentAddress"
                  cols="12"
                  sm="12"
                  md="12"
                  lg="6"
                  xl="6"
                >
                  <v-btn small class="mb-1" color="primary" @click="setSendingAddress"
                    >Hae vuokralaisen osoite</v-btn
                  >
                </v-col>
              </v-row>

              <v-row v-if="!invoice.sendToApartmentAddress" dense
                ><v-col cols="12" sm="12" md="6" lg="6" xl="3">
                  <v-checkbox
                    v-model="invoice.sendingAddress.addExtraLine"
                    style="margin-top: 0px"
                    label="Lisää uusi osoiterivi (esim. Docuscan)"
                    hide-details
                    @change="invoice.sendingAddress.addressLine2 = null"
                  ></v-checkbox> </v-col
              ></v-row>

              <v-row dense>
                <v-col
                  v-if="invoice.sendingAddress.addExtraLine"
                  cols="12"
                  sm="12"
                  md="6"
                  lg="6"
                  xl="3"
                >
                  <v-text-field
                    v-model="invoice.sendingAddress.addressLine2"
                    label="Osoiterivi 1"
                    placeholder="Esim. OVT 12345678"
                    outlined
                    dense
                    hide-details
                    :rules="validations.required"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="12" md="6" lg="6" xl="3">
                  <v-text-field
                    v-model="invoice.sendingAddress.address"
                    :label="invoice.sendingAddress.addExtraLine ? 'Osoiterivi 2' : 'Osoite'"
                    placeholder="Esim. Testikatu 5 A 7"
                    :rules="validations.required"
                    outlined
                    dense
                    hide-details
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="12" md="6" lg="6" xl="3">
                  <v-text-field
                    v-model="invoice.sendingAddress.zipCode"
                    label="Postinumero"
                    :rules="validations.required"
                    outlined
                    dense
                    hide-details
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="12" md="6" lg="6" xl="3">
                  <v-text-field
                    v-model="invoice.sendingAddress.city"
                    label="Kaupunki"
                    :rules="validations.required"
                    outlined
                    dense
                    hide-details
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="12" md="6" lg="6" xl="3">
                  <v-autocomplete
                    v-model="invoice.sendingAddress.country"
                    :items="getCountries()"
                    item-text="text"
                    item-value="code"
                    clearable
                    outlined
                    label="Maa"
                    dense
                    :rules="[
                      isRequiredByInvoiceService({ ropoCapital: false, talenom: true }),
                      isInvoiceAddressCountryAvailable,
                    ]"
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-card>

      <v-card class="mt-2">
        <v-card-title>Laskun tiedot</v-card-title>
        <v-container>
          <v-row dense>
            <v-col cols="12" sm="6" md="4" lg="3" xl="2">
              <v-menu
                ref="billDateMenu"
                v-model="billDateMenu"
                :close-on-content-click="false"
                min-width="290"
              >
                <template #activator="{ on, attrs }">
                  <v-text-field
                    v-model="dateFormattedBillDate"
                    v-bind="attrs"
                    :rules="validations.required"
                    outlined
                    dense
                    v-on="on"
                    append-icon="mdi-calendar"
                    label="Laskun pvm."
                    v-prevent-manual-input
                    :readonly="true"
                    hide-details
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="invoice.billDate"
                  :allowed-dates="allowedBillDates"
                  first-day-of-week="1"
                  @input="billDateMenu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>

            <v-col cols="12" sm="6" md="4" lg="3" xl="2">
              <v-menu
                ref="accountDateMenu"
                v-model="accountDateMenu"
                :close-on-content-click="false"
                min-width="290"
              >
                <template #activator="{ on, attrs }">
                  <v-text-field
                    v-model="dateFormattedAccountDate"
                    v-bind="attrs"
                    :rules="validations.required"
                    outlined
                    dense
                    v-on="on"
                    append-icon="mdi-calendar"
                    label="Laskun kirjauspvm."
                    v-prevent-manual-input
                    :readonly="true"
                    hide-details
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="invoice.accountDate"
                  :allowed-dates="allowedBillDates"
                  first-day-of-week="1"
                  @input="accountDateMenu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>

            <v-col cols="12" sm="6" md="4" lg="3" xl="2">
              <v-menu
                ref="firstDueDateMenu"
                v-model="firstDueDateMenu"
                :close-on-content-click="false"
                min-width="290"
              >
                <template #activator="{ on, attrs }">
                  <v-text-field
                    v-model="dateFormattedFirstDueDate"
                    v-bind="attrs"
                    :rules="validations.required"
                    outlined
                    dense
                    v-on="on"
                    append-icon="mdi-calendar"
                    label="Laskun eräpäivä"
                    v-prevent-manual-input
                    :readonly="true"
                    hide-details
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="invoice.dueDate"
                  :allowed-dates="allowedDueDates"
                  first-day-of-week="1"
                  @input="firstDueDateMenu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>

            <v-col cols="12" sm="6" md="4" lg="3" xl="2">
              <v-text-field
                v-model="invoice.yourCode"
                outlined
                dense
                label="Ostajan viite"
                hide-details
              ></v-text-field>
              <small>Näkyy laskulla "Viitteenne"</small>
            </v-col>
          </v-row>

          <v-row dense class="mt-1">
            <v-col cols="12" md="5" lg="4">
              <div v-if="invoice.dueDate" class="invoice-overview">
                <p>
                  Laskun eräpäivä on
                  <span class="contract-text">{{ formatDate(invoice.dueDate) }} </span>
                </p>
                <p>
                  Laskun lähetystapa on
                  <span class="contract-text">{{ formatSendType(invoice.sendType) }} </span>
                </p>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-card>

      <!-- Products -->

      <v-card class="mt-2">
        <v-card-title>Tuotteet</v-card-title>
        <v-card-subtitle class="error--text"
          >Hyvityslaskun tuotemäärä annetaan negatiivisena ja hinta positiivisena</v-card-subtitle
        >
        <v-container>
          <div v-for="(product, index) in invoice.products" :key="index">
            <v-row dense>
              <v-col cols="6" md="3" lg="2">
                <label>Valitse tuote</label>
                <v-autocomplete
                  :value="product.productId"
                  :items="products"
                  item-text="desc"
                  item-value="_id"
                  dense
                  outlined
                  return-object
                  small-chips
                  @focus="getInvoiceProducts"
                  @change="setProduct($event, { ...product }, index)"
                  :rules="[isRequiredByInvoiceService({ ropoCapital: false, talenom: true })]"
                  hide-details
                ></v-autocomplete>
              </v-col>
            </v-row>

            <v-row dense>
              <v-col cols="6" md="2" lg="2">
                <v-text-field
                  v-model.number="product.desc"
                  outlined
                  dense
                  :rules="validations.required"
                  label="Kuvaus"
                  hide-details
                />
              </v-col>
              <v-col cols="6" md="2" lg="2">
                <v-text-field
                  v-model.number="product.amount"
                  outlined
                  dense
                  step="0.01"
                  type="number"
                  label="Hinta"
                  suffix="€"
                  :rules="validations.required.concat(validations.positiveNumber)"
                  hide-details
                />
              </v-col>

              <v-col cols="6" md="2" lg="1">
                <v-text-field
                  v-model.number="product.count"
                  outlined
                  dense
                  v-only-numbers
                  v-prevent-paste
                  label="Määrä"
                  :rules="validations.required.concat(validations.negativeNumber)"
                  hide-details
                ></v-text-field>
              </v-col>

              <v-col cols="6" md="2" lg="2" xl="1">
                <v-select
                  v-model="product.itemtype"
                  outlined
                  dense
                  :items="productItemTypes"
                  label="Tuotetyyppi"
                  return-object
                  :rules="validations.required"
                  hide-details
                ></v-select>
              </v-col>

              <v-col cols="6" md="3" lg="2">
                <div class="details-container">
                  <v-select
                    v-model.number="product.taxpr"
                    outlined
                    dense
                    :items="vatTypes"
                    item-value="val"
                    item-text="text"
                    label="Alv-kanta"
                    :rules="validations.zeroOrGreater"
                    hide-details
                  ></v-select>

                  <v-icon class="ml-1" color="error" small @click="deleteProduct(index)"
                    >mdi-delete</v-icon
                  >
                </div>
              </v-col>
            </v-row>

            <div v-if="product.accountingId" style="margin-top: 5px">
              <p style="font-size: 13px">Tiliöintikoodi: {{ product.accountingId }}</p>
              <v-divider class="mb-1 mt-2"></v-divider>
            </div>

            <v-divider v-else class="mb-1 mt-2"></v-divider>
          </div>

          <v-row class="mb-2" :class="{ 'mt-2': invoice.products.length > 0 }" dense>
            <v-col cols="12">
              <v-btn color="primary" @click="addProduct">Lisää tuoterivi</v-btn>
            </v-col>
          </v-row>

          <v-row v-if="isCompany && checkVatFreeProducts()" class="mb-2" dense>
            <v-col cols="6" md="4" lg="3">
              <label style="margin: 0px">Alv 0 % selite</label>
              <v-text-field
                v-model="invoice.vatFreeText"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>

          <!-- VATS -->
          <vat-component
            v-if="invoice.products.length > 0"
            class="mb-2"
            :products="invoice.products"
          ></vat-component>
        </v-container>
      </v-card>

      <!-- Other Data -->
      <v-card class="mt-2">
        <v-card-title>Muut tiedot</v-card-title>
        <v-container>
          <v-row dense>
            <v-col cols="6" md="4" lg="3" xl="2">
              <h3 class="mb-1">Laskun kieli</h3>
              <v-select
                v-model="invoice.language"
                item-text="text"
                item-value="val"
                :items="getServiceInvoiceLanguages()"
                outlined
                dense
                label="Laskun kieli"
                :rules="validations.required"
                hide-details
              ></v-select>
            </v-col>
          </v-row>

          <v-divider class="mt-2 mb-1"></v-divider>

          <v-row v-if="currentUser.currentAccount.settings.outgoingPayments" dense align="center">
            <v-col cols="12" sm="6" md="5" lg="4" xl="3">
              <h3>Lähtevien maksujen seuranta</h3>
              <v-checkbox
                style="margin-top: 3px"
                v-model="invoice.outgoingPayments.inUse"
                label="Lisää kuluna lähteviin maksuihin"
                class="mt-1"
                hide-details
              ></v-checkbox>
            </v-col>

            <v-col cols="12" sm="6" md="5" lg="4" xl="2">
              <v-menu
                v-if="invoice.outgoingPayments.inUse"
                ref="menu"
                v-model="menuOutgoing"
                :close-on-content-click="false"
                max-width="290"
              >
                <template #activator="{ on, attrs }">
                  <v-text-field
                    v-model="dateFormattedOutgoing"
                    dense
                    outlined
                    v-bind="attrs"
                    :rules="validations.required"
                    v-on="on"
                    append-icon="mdi-calendar"
                    label="Lähtevän maksun kuukausi"
                    v-prevent-manual-input
                    :readonly="true"
                    hide-details
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="invoice.outgoingPayments.subtractDate"
                  :allowed-dates="allowedMonths"
                  type="month"
                  first-day-of-week="1"
                  @input="menuOutgoing = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>

          <v-divider
            v-if="currentUser.currentAccount.settings.outgoingPayments"
            class="mt-1 mb-1"
          ></v-divider>

          <v-row class="mt-1" dense>
            <v-col v-if="invoice.contract" cols="12" sm="6" md="5" lg="4" xl="3">
              <!-- PERSONALREFNUM -->
              <personal-ref-num-component
                :principalTenant="principalTenant"
                :apartment="currentApartment"
                :currentRefNum="invoice.referenceNumber"
                @switchprincipaltenantpersonalrefnumuse="switchPrincipalTenantRefNumUse"
              ></personal-ref-num-component>
            </v-col>

            <v-col cols="12" sm="6" md="5" lg="4" xl="3">
              <!-- COSTCENTRE  -->
              <CostCentre :contract="invoice.contract" :invoice="invoice"></CostCentre>
            </v-col>

            <v-col cols="12" sm="10" md="8" lg="6" xl="5">
              <h3 class="mb-1" style="font-size: 17px !important; margin-bottom: 0px !important">
                <strong v-if="showBasedOnService({ talenom: false, ropoCapital: true })">
                  Vapaa teksti ja liite
                </strong>
                <strong v-if="showBasedOnService({ talenom: true, ropoCapital: false })">
                  Vapaa teksti
                </strong>
              </h3>

              <v-row dense>
                <v-col cols="12" sm="8">
                  <v-textarea
                    v-model="invoice.freeText"
                    outlined
                    hide-details
                    class="mt-2"
                    label="Laskun vapaa teksti"
                  ></v-textarea>
                </v-col>
              </v-row>

              <v-row v-if="showBasedOnService({ talenom: false, ropoCapital: true })" dense>
                <v-col cols="12">
                  <div>
                    <v-checkbox
                      style="margin-top: 0 !important; padding-top: 0 !important"
                      v-model="invoice.addAttachment"
                      label="Lisää pdf-liite"
                      @change="handleAddAttachmentChange"
                      hide-details
                    ></v-checkbox>

                    <small v-if="invoice.addAttachment" class="error--text"
                      >A4-lisäsivun hinta on 0,07 €/kpl</small
                    >
                  </div>

                  <div v-if="invoice.addAttachment">
                    <v-radio-group
                      v-model="invoice.userCreatedAttachment"
                      :mandatory="true"
                      style="margin-top: 5px"
                      @change="handleUserCreatedAttachmentChange"
                    >
                      <v-radio label="Luo oma pdf-tiedosto" :value="true"></v-radio>
                      <v-radio label="Liitä valmis pdf-tiedosto" :value="false"></v-radio>
                    </v-radio-group>

                    <!-- Editor -->
                    <QuillEditor v-if="showQuillEditor" ref="QuillEditor"></QuillEditor>
                    <!-- Add file -->
                    <upload-pdf v-if="showUploadPdf" ref="uploadPdf"></upload-pdf>
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-btn color="info" :loading="saving" class="mr-4 mt-5" @click="submit">{{
            saveBtnText
          }}</v-btn>
        </v-container>
      </v-card>
    </v-form>
  </div>
</template>

<script>
import mixins from "../../mixins/mixins";
import invoiceMixins from "../../mixins/invoiceMixins";
import moment from "moment";
import _ from "lodash";
import { debounce } from "../../utils/helpers";
import CostCentre from "./CostCentre.vue";
import PersonalRefNumComponent from "./PersonalRefNumComponent.vue";
import { mapState, mapActions, mapMutations } from "vuex";
import globalValues from "../../configs/globalValues";
import validations from "@/validations";
import FullPageLoader from "@/components/FullPageLoader";
import invoiceHelpers from "@/utils/invoiceHelpers";
import VatComponent from "@/components/Invoice/VatComponent";
import QuillEditor from "../QuillEditor.vue";
import UploadPdf from "@/components/UploadPdf.vue";

export default {
  props: ["saveBtnText", "edit"],

  mixins: [mixins, invoiceMixins],

  components: {
    QuillEditor,
    CostCentre,
    PersonalRefNumComponent,
    FullPageLoader,
    VatComponent,
    UploadPdf,
  },

  data() {
    return {
      searchInput: "",
      firstDueDateMenu: false,
      billDateMenu: false,
      accountDateMenu: false,
      menuOutgoing: false,
      dateFormattedBillDate: "",
      dateFormattedAccountDate: "",
      dateFormattedFirstDueDate: "",
      dateFormattedOutgoing: "",
      contractInput: "",
      principalTenant: { tenantId: { personalRefNumInUse: false } },
      showQuillEditor: true,
      showUploadPdf: false,
      invoice: {
        yourCode: "",
        language: "fin",
        contract: null,
        tenant: { email: "", email2: "", name: "" },
        refundInvoiceIds: [],
        autoReminder: false,
        isRecurrent: false,
        billDateAsDueDate: false,
        shareAmountPerDays: false,
        otherDueDates: null,
        billDate: null,
        dueDate: null,
        accountDate: null,
        products: [],
        sendType: "post",
        autoSendType: "print",
        addAttachment: false,
        userCreatedAttachment: true,
        pdfEditorContent: "",
        base64Pdf: {
          base64FileString: "",
          fileName: "",
        },
        freeText: "Hyvityslasku sopimuksen mukaan",
        vatFreeText: "",
        sendToApartmentAddress: true,
        sendingAddress: {
          addExtraLine: false,
          addressLine2: null,
          address: null,
          zipCode: null,
          city: null,
          country: null,
        },
        evoice: {
          evoiceOperator: "",
          evoiceId: "",
          evoiceAddress: "",
        },
        outgoingPayments: { inUse: false, subtractDate: "" },
      },
      vatTypes: globalValues.vatTypes,
      productItemTypes: globalValues.productItemTypes,
      invoiceHelpers,
      validations,
    };
  },

  computed: {
    ...mapState("product", ["products"]),
    ...mapState("contract", ["activeRentalContracts"]),
    ...mapState("invoice", ["currentInvoice", "loading", "saving"]),
    ...mapState("account", ["currentUser"]),

    currentApartment() {
      return this.invoice?.contract?.apartment?.id || null;
    },

    isCompany() {
      return this.$store.state.account.currentUser.currentAccount.isCompany;
    },

    sendTypes() {
      return globalValues.sendTypes(this.principalTenant.tenantId.isCompany, this.isCompany);
    },

    invoiceReceivers() {
      if (this.invoice.contract) return invoiceHelpers.getInvoiceReceivers(this.invoice.contract);
      else return [];
    },
  },

  watch: {
    async currentInvoice(val) {
      this.invoice = _.cloneDeep(val);

      // Set principal tenant
      const tenant = invoiceHelpers.getInvoiceTenantByClientId(
        this.invoice.contract,
        this.invoice.tenant.clientId
      );

      // update info and set principal tenant
      this.principalTenant = tenant;

      // Get products
      this.getInvoiceProducts();

      // set dates
      this.invoice.billDate = new Date(this.invoice.billDate).toISOString().substring(0, 10);
      this.invoice.accountDate = new Date(this.invoice.accountDate).toISOString().substring(0, 10);
      this.invoice.dueDate = new Date(this.invoice.dueDate).toISOString().substring(0, 10);
      this.invoice.outgoingPayments.inUse = !this.currentUser.currentAccount.settings
        .outgoingPayments
        ? false
        : this.invoice.outgoingPayments.inUse;

      if (this.invoice.outgoingPayments.subtractDate) {
        this.invoice.outgoingPayments.subtractDate = new Date(
          this.invoice.outgoingPayments.subtractDate
        )
          .toISOString()
          .substring(0, 7);
      }

      // Set contract to actice contracts
      this.$store.state.contract.activeRentalContracts = [{ ...this.invoice.contract }];

      // Set loading
      this.setLoading(false);

      // this set free text to quill editor if pdf in use
      if (this.invoice.addAttachment) {
        if (this.invoice.userCreatedAttachment && this.invoice.pdfEditorContent) {
          this.showQuillEditor = true;
          this.showUploadPdf = false;
          await this.$nextTick();
          this.$refs.QuillEditor.setContent(this.invoice.pdfEditorContent);
        } else if (
          !this.invoice.userCreatedAttachment &&
          this.invoice.base64Pdf?.base64FileString &&
          this.invoice.base64Pdf?.fileName
        ) {
          this.showUploadPdf = true;
          this.showQuillEditor = false;
          await this.$nextTick();
          this.$refs.uploadPdf.setContent(this.invoice.base64Pdf);
        }
      } else {
        this.invoice.userCreatedAttachment = true;
        this.showQuillEditor = true;
        this.showUploadPdf = false;
      }
    },

    "invoice.dueDate"() {
      this.dateFormattedFirstDueDate = this.formatDate(this.invoice.dueDate);
    },

    "invoice.billDate"() {
      this.dateFormattedBillDate = this.formatDate(this.invoice.billDate);
    },

    "invoice.accountDate"() {
      this.dateFormattedAccountDate = this.formatDate(this.invoice.accountDate);
    },

    "invoice.outgoingPayments.subtractDate"() {
      this.dateFormattedOutgoing = this.invoice.outgoingPayments.subtractDate;
    },

    currentUser(val) {
      if (this.$route.path.includes("create")) {
        this.invoice.outgoingPayments.inUse = val.currentAccount.settings.outgoingPayments;
      }
    },

    contractInput: debounce(function (newVal) {
      this.searchContract(`/api/v1/rental-contract/search-rental-contract?search=${newVal}`);
    }, 1000),
  },

  created() {
    if (this.$route.path.includes("create")) {
      this.invoice.outgoingPayments.inUse =
        this.currentUser.currentAccount.settings.outgoingPayments;
    }
  },

  methods: {
    ...mapActions("invoice", ["createRefundInvoice", "editRefundInvoice"]),
    ...mapActions("product", ["getInvoiceProducts"]),
    ...mapActions("contract", ["searchContract"]),
    ...mapMutations("invoice", ["setLoading", "setSaving"]),

    handleAddAttachmentChange(val) {
      if (!val && this.$refs.QuillEditor) this.$refs.QuillEditor.deleteContent();
      if (!val && this.$refs.uploadPdf) this.$refs.uploadPdf.deleteContent();
    },

    handleUserCreatedAttachmentChange(val) {
      if (val) {
        if (this.$refs.uploadPdf) this.$refs.uploadPdf.deleteContent();
        this.showUploadPdf = false;
        this.showQuillEditor = true;
      } else {
        if (this.$refs.QuillEditor) this.$refs.QuillEditor.deleteContent();
        this.showQuillEditor = false;
        this.showUploadPdf = true;
      }
    },

    switchPrincipalTenantRefNumUse() {
      this.principalTenant.tenantId.personalRefNumInUse =
        !this.principalTenant.tenantId.personalRefNumInUse;

      // Delete other contracts from active because they might have wrong
      this.$store.state.contract.activeRentalContracts = [{ ...this.invoice.contract }];
    },

    getRentalContract(event) {
      this.contractInput = event.target.value;
    },

    nameContractnumber(item) {
      if (item.tenant) {
        return item.contractNumber + ", " + item.tenant.name;
      }
    },

    setInvoiceFields(val) {
      if (val) {
        // Set principal tenant, Get first active (not revoked) tenant
        const tenant = invoiceHelpers.getPrincipalTenant(val);
        this.principalTenant = { ...tenant };

        // Set tenant
        this.updateReceiverInfo(tenant);

        // Set landlords
        this.invoice.landlords = [...val.landlords];

        if (
          this.canSetInvoiceLanguage({ ropoCapital: true, talenom: true }, this.principalTenant)
        ) {
          this.invoice.language = this.principalTenant.tenantId.invoiceLanguage;
        }

        // Set sending address
        if (this.invoice.sendToApartmentAddress) {
          const apartmentNumber = val.apartment.id.apartmentNumber
            ? val.apartment.id.apartmentNumber
            : "";
          this.invoice.sendingAddress.address = `${val.apartment.id.address} ${apartmentNumber}`;
          this.invoice.sendingAddress.zipCode = val.apartment.id.zipCode;
          this.invoice.sendingAddress.city = val.apartment.id.city;
          this.invoice.sendingAddress.country = val.apartment.id.country || null;
        } else {
          this.invoice.sendingAddress.address = val.tenant.tenantId.address;
          this.invoice.sendingAddress.zipCode = val.tenant.tenantId.zipCode;
          this.invoice.sendingAddress.city = val.tenant.tenantId.city;
          this.invoice.sendingAddress.country = val.tenant.tenantId.country || null;
        }
      }
    },

    updateInvoiceLanguage() {
      if (this.canSetInvoiceLanguage({ ropoCapital: true, talenom: true }, this.principalTenant)) {
        const currentLanguage = this.invoice.language;
        this.invoice.language = this.principalTenant.tenantId.invoiceLanguage;
        if (currentLanguage !== this.invoice.language)
          this.showPopup("Huomaa muuttunut laskun kieli", "info");
      }
    },

    updateReceiverInfo(tenant) {
      if (tenant) {
        this.invoice.tenant.name = tenant.tenantId.name;
        this.invoice.tenant.email = tenant.tenantId.email ? tenant.tenantId.email : "";

        // Update tenant address info
        this.invoice.tenant.address = this.invoice.contract.apartment.id.address;
        this.invoice.tenant.apartmentNumber =
          this.invoice.contract.apartment.id.apartmentNumber || "";
        this.invoice.tenant.zipCode = this.invoice.contract.apartment.id.zipCode;
        this.invoice.tenant.city = this.invoice.contract.apartment.id.city;

        if (!tenant.tenantId.isCompany) {
          this.invoice.evoice.evoiceOperator = "";
          this.invoice.evoice.evoiceId = "";
          this.invoice.evoice.evoiceAddress = "";
          if (this.invoice.sendType === "evoice") {
            this.invoice.sendType = "print";
            this.showPopup(
              "Verkkolasku vaihdettu itsetulostukseksi. Vastaanottaja ei ole yritys.",
              "info"
            );
          }
        }
      }
    },

    getTenantEvoiceInformation() {
      this.invoice.evoice.evoiceOperator = this.principalTenant.tenantId.evoice.evoiceOperator;
      this.invoice.evoice.evoiceId = this.principalTenant.tenantId.evoice.evoiceId;
      this.invoice.evoice.evoiceAddress = this.principalTenant.tenantId.evoice.evoiceAddress;
    },

    setSendingAddress() {
      if (this.invoice.sendToApartmentAddress) {
        this.invoice.sendingAddress.addExtraLine = false;
        this.invoice.sendingAddress.addressLine2 = null;
        const apartmentNumber = this.invoice.contract.apartment.id.apartmentNumber
          ? this.invoice.contract.apartment.id.apartmentNumber
          : "";
        this.invoice.sendingAddress.address = `${this.invoice.contract.apartment.id.address} ${apartmentNumber}`;
        this.invoice.sendingAddress.zipCode = this.invoice.contract.apartment.id.zipCode;
        this.invoice.sendingAddress.city = this.invoice.contract.apartment.id.city;
        this.invoice.sendingAddress.country = this.invoice.contract.apartment.id.country || null;
      } else {
        this.invoice.sendingAddress.address = this.principalTenant.tenantId.address;
        this.invoice.sendingAddress.zipCode = this.principalTenant.tenantId.zipCode;
        this.invoice.sendingAddress.city = this.principalTenant.tenantId.city;
        this.invoice.sendingAddress.country = this.principalTenant.tenantId.country || null;
      }
    },

    checkVatFreeProducts() {
      let boolean = false;
      this.invoice.products.forEach((el) => {
        if (el.taxpr == 0) boolean = true;
      });

      return boolean;
    },

    allowedBillDates(val) {
      const today = new Date(moment(new Date()).format("YYYY-MM-DD"));

      const show = new Date(val).getTime() >= today ? true : false;
      return show;
    },

    allowedDueDates(val) {
      const today = new Date(moment(new Date()).format("YYYY-MM-DD"));

      const show = new Date(val).getTime() > today ? true : false;
      return show;
    },

    allowedMonths(val) {
      const time = new Date(val).getTime();
      const startOfMonth = new Date(moment(new Date()).startOf("month")).getTime();
      if (time >= startOfMonth) return true;
      else return false;
    },

    addProduct() {
      this.invoice.products.push({
        desc: "",
        count: null,
        itemtype: "kpl",
        amount: null,
        taxpr: 0,
        accountingId: null,
        productId: null,
      });
    },

    deleteProduct(index) {
      this.invoice.products.splice(index, 1);
    },

    setProduct(event, product, index) {
      if (event) {
        product.productNumber = event.productNumber;
        product.desc = event.desc;
        product.amount = event.amount;
        product.count = event.count * -1;
        product.taxpr = event.taxpr;
        product.itemtype = event.itemtype;
        product.accountingId = event.accountingId;
        product.productId = event._id;
        delete product._id;

        this.invoice.products.splice(index, 1, product);
      }
    },

    async submit() {
      try {
        const dueDate = new Date(this.invoice.dueDate).getTime();
        const accountDate = new Date(this.invoice.accountDate).getTime();
        const billDate = new Date(this.invoice.billDate).getTime();

        if (this.$refs.form.validate()) {
          if (billDate > accountDate || billDate > dueDate) {
            return this.showPopup(
              "Laskun päiväys ei voi olla suurempi kuin kirjauspäivä tai eräpäivä.",
              "error"
            );
          }

          // checkDates
          if (accountDate > dueDate) {
            return this.showPopup(
              "Laskun kirjauspäivä ei voi olla suurempi kuin eräpäivä.",
              "error"
            );
          }

          if (this.invoice.products.length == 0) {
            return this.showPopup("Lisää ainakin yksi tuoterivi", "error");
          }

          // Get pdf content
          if (this.invoice.addAttachment) {
            if (this.showUploadPdf && this.$refs.uploadPdf && !this.invoice.userCreatedAttachment) {
              const obj = this.$refs.uploadPdf.getContent();
              this.invoice.base64Pdf.fileName = obj.fileName;
              this.invoice.base64Pdf.base64FileString = obj.base64FileString;
            } else if (
              this.showQuillEditor &&
              this.$refs.QuillEditor &&
              this.invoice.userCreatedAttachment
            ) {
              this.invoice.pdfEditorContent = this.$refs.QuillEditor.getContent();
            }
          }

          // Set saving
          this.setSaving(true);

          if (this.edit) {
            // Edit
            await this.editRefundInvoice({
              url: `/api/v1/invoices/refund/edit/${this.$route.params.id}`,
              invoice: this.invoice,
              principalTenant: this.principalTenant,
            });
          } else {
            // Create new
            await this.createRefundInvoice({
              invoice: this.invoice,
              principalTenant: this.principalTenant,
            });
          }
        } else {
          this.showPopup("Jotkin kentät ovat puutteellisesti täydennetty", "error");
        }
      } catch (err) {
        this.setSaving(false);
        this.showPopup(err, "error");
      }
    },
  },
};
</script>

<style scoped>
p {
  line-height: 17px !important;
  margin-bottom: 6px !important;
  font-size: 15px;
}

.details-container {
  display: flex;
}

.contract-text {
  font-size: 14px;
  font-weight: 600;
  color: rgb(51, 51, 51);
}

.contract-box {
  max-width: 1000px;
}

.invoice-overview {
  padding: 20px;
  width: 100%;
  border: 2px solid var(--v-success-base);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
}

.invoice-overview p {
  padding: 0;
  margin: 0;
}

.tooltip-box {
  background-color: rgb(131, 131, 131);
  color: white;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.v-tooltip__content {
  font-size: 14px !important;
  opacity: 0.9 !important;
  background-color: rgb(78, 78, 78);
  padding: 25px;
}

.product-wrapper {
  max-width: 280px;
  display: flex;
  align-content: center;
  justify-content: space-between;
}
</style>
